<template>
  <div class="client page-list">
    <v-card>
      <v-card-title> <h1>Clientes</h1> </v-card-title>
      <v-data-table
        :mobile-breakpoint="768"
        :headers="tableHeaders"
        :items="listData"
        :search="search"
        :loading="loaderTable"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50, 100, -1],
        }"
        loading-text="Cargando... Por favor espere."
      >
        <template v-slot:[`item.dni`]="{ item }">
          {{ item.dni | unitMile }}
        </template>

        <template v-slot:[`item.segment`]="{ item }">
          {{ item.segment.name }}
        </template>

        <template v-slot:[`item.seller`]="{ item }">
          {{ item.seller.name }} {{ item.seller.lastName }}
        </template>

        <template v-slot:[`item.actions`]="{ item, index }">
          <CustomButtonAction
            :value="item"
            :btnDetail="true"
            :btnEdit="true"
            :btnDelete="true"
            :index="index"
            :loaderDelete="loaderDelete"
            @onChangeDetail="detailItem($event)"
            @onChangeEdit="editItem($event)"
            @onChangeDelete="deleteItem($event, index)"
          />
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="11" md="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar ..."
                  single-line
                  hide-details
                  outlined
                  dense
                  clearable
                  color="second"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-spacer></v-spacer>

            <EditClient
              :dialog.sync="editModal"
              :edit.sync="editView"
              :data="selectItem"
              @onChange="firstRowData($event)"
            />

            <CustomDetails
              :dialog.sync="detailModal"
              :item="selectItem"
              :type="'client'"
            />

            <CustomDialog
              :dialog.sync="deleteModal"
              :title="'Confirmar eliminación'"
              :message="'¿Estás seguro que quieres eliminar el registro?'"
              :maxWidth="'320'"
              @onChange="btnDelete()"
            ></CustomDialog>
          </v-toolbar>
          <v-divider></v-divider>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import EditClient from "./edit-client.vue";
import useColumns from "./useColumns";
import ClientServices from "../../../../core/services/client-services";
import ClientStorage from "../../../../core/storage/client-storage";
import CustomButtonAction from "../../../../shared/components/custom-button-action/CustomButtonAction.vue";
import CustomDetails from "../../../../shared/components/custom-details/CustomDetails.vue";
import CustomDialog from "../../../../shared/components/custom-dialog/CustomDialog.vue";

export default {
  name: "Client",
  data: () => ({
    search: "",
    editView: false,
    editModal: false,
    detailModal: false,
    deleteModal: false,
    selectItem: {},
    listData: [],
    loaderTable: false,
    loaderDelete: {},
    selectIndex: null,
    tableHeaders: useColumns,
  }),
  components: {
    EditClient,
    CustomButtonAction,
    CustomDetails,
    CustomDialog,
  },
  created() {
    this.clientAllStorage();
    this.getClientAll();
  },
  methods: {
    detailItem(item) {
      this.detailModal = true;
      this.selectItem = item;
    },
    editItem(item) {
      this.editModal = true;
      this.editView = true;
      this.selectItem = item;
    },
    deleteItem(item, index) {
      this.deleteModal = true;
      this.selectItem = item;
      this.selectIndex = index;
    },
    async clientAllStorage() {
      const { clientAll } = ClientStorage();
      this.listData = await clientAll();
    },
    getClientAll() {
      this.loaderTable = true;
      const { getClientAll } = ClientServices();
      const { clientAdd } = ClientStorage();
      getClientAll().then((resp) => {
        const response = resp.data;
        if (response.code == 200) {
          response.data.map((item) => {
            clientAdd(item);
          });
          this.clientAllStorage();
        }
        this.loaderTable = false;
      });
    },
    firstRowData(data) {
      const { clientAdd } = ClientStorage();
      clientAdd(data);
      this.clientAllStorage();
    },
    btnDelete() {
      const { deleteClientId } = ClientServices();
      const { clientDeleteById } = ClientStorage();
      const index = this.selectIndex;
      this.loaderDelete = { [index]: true };

      deleteClientId(this.selectItem.id).then((resp) => {
        const response = resp.data;
        if (response.code == 200) {
          clientDeleteById(this.selectItem.id);
          this.clientAllStorage();
          this.$toast.success("El registro ha sido eliminado exitosamente");
        }
        this.loaderDelete = { [index]: false };
      });
    },
  },
};
</script>
