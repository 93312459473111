<template>
  <v-dialog
    v-model="viewModal"
    max-width="620px"
    persistent
    scrollable
    content-class="admin-dialog edit-cliet-admin"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="second"
        class="mb-1 white--text"
        v-bind="attrs"
        v-on="on"
        @click="btnDialog()"
      >
        Registrar
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="card-title">
        <span class="headline">{{ edit ? "Editar" : "Registrar" }}</span>

        <v-spacer></v-spacer>

        <CustomButtonAction
          :btnApply="true"
          :btnCancel="true"
          :loaderApply="loading"
          colorBtnApply="white"
          colorBtnCancel="white"
          @onChangeApply="apply()"
          @onChangeCancel="cancel()"
        />
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Cédula"
                  v-model="item.dni"
                  :autocomplete="'off'"
                  :rules="dniRules"
                  :maxlength="15"
                  dense
                  color="second"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Nombre"
                  v-model="item.name"
                  :autocomplete="'off'"
                  :rules="requiredRules"
                  :maxlength="100"
                  dense
                  color="second"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  label="Apellido"
                  v-model="item.lastName"
                  :autocomplete="'off'"
                  :rules="requiredRules"
                  :maxlength="100"
                  dense
                  color="second"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Teléfono"
                  v-model="item.phone"
                  :autocomplete="'off'"
                  :rules="numberRules"
                  :maxlength="20"
                  dense
                  color="second"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  label="Correo electrónico"
                  v-model="item.email"
                  :autocomplete="'off'"
                  :rules="emailRules"
                  :maxlength="150"
                  dense
                  color="second"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                  :autocomplete="'off'"
                  label="Dirección"
                  v-model="item.address"
                  :rules="requiredRules"
                  :maxlength="1000"
                  dense
                  rows="3"
                  color="second"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="Seleccionar vendedor"
                  clearable
                  v-model="item.seller"
                  :rules="requiredRules"
                  :items="listSeller"
                  :item-text="(item) => `${item.name} ${item.lastName}`"
                  item-value="_id"
                  dense
                  color="second"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomButtonAction from "../../../../shared/components/custom-button-action/CustomButtonAction.vue";
import useValidate from "../../../../shared/validate/useValidate";
import MarkServices from "../../../../core/services/mark-services";
import MarkStorage from "../../../../core/storage/mark-storage";
import SellerStorage from "../../../../core/storage/seller-storage";
import UserServices from "../../../../core/services/user-services";
import ClientServices from "../../../../core/services/client-services";

export default {
  name: "EditClient",
  data: () => ({
    viewModal: false,
    valid: false,
    loading: false,
    item: {},
    listMark: [],
    listSeller: [],
    requiredRules: [useValidate["required"]],
    dniRules: [useValidate["required"], useValidate["dni"]],
    emailRules: [useValidate["email"]],
    numberRules: [useValidate["required"], useValidate["phone"]],
  }),
  components: {
    CustomButtonAction,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    data: {},
  },
  watch: {
    dialog: function (value) {
      this.viewModal = value;
    },
    edit: function (value) {
      if (value) {
        this.item = Object.assign({}, this.data);
      }
    },
  },
  created() {
    this.markAll();
    this.sellerAll();
    this.getMarkAll();
    this.getAllUser();
  },
  methods: {
    btnDialog() {
      this.viewModal = true;
    },
    apply() {
      const validate = this.$refs.form.validate();
      if (validate) {
        if (this.edit) {
          this.btnEdit();
        } else {
          this.btnSave();
        }
      }
    },
    cancel() {
      this.viewModal = false;
      this.$emit("update:dialog", false);
      this.$emit("update:edit", false);
      setTimeout(() => {
        this.$refs.form.reset();
      }, 500);
    },
    btnSave() {
      this.loading = true;
      const { saveClient } = ClientServices();
      saveClient(this.item).then((resp) => {
        const response = resp.data;
        if (response.code == 201) {
          this.$toast.success("El cliente se ha registrado de forma exitosa");
          this.$emit("onChange", response.data);
          this.cancel();
        }
        this.loading = false;
      });
    },
    btnEdit() {
      this.loading = true;
      const { updateClient } = ClientServices();

      this.item.seller = this.item.seller.name
        ? this.item.seller._id
        : this.item.seller;

      updateClient(this.item.id, this.item).then((resp) => {
        const response = resp.data;
        if (response.code == 201) {
          this.$toast.success("El cliente se ha actualizado de forma exitosa");
          this.$emit("onChange", response.data);
          this.cancel();
        }
        this.loading = false;
      });
    },
    async markAll() {
      const { markAll } = MarkStorage();
      this.listMark = await markAll();
    },
    async sellerAll() {
      const { sellerAll } = SellerStorage();
      this.listSeller = await sellerAll();
    },
    getMarkAll() {
      const { getMarkAll } = MarkServices();
      const { markAdd } = MarkStorage();
      getMarkAll().then((resp) => {
        const response = resp.data;
        if (response.code == 200) {
          response.data.map((item) => {
            markAdd(item);
          });
          this.markAll();
        }
      });
    },
    getAllUser() {
      const { userAll } = UserServices();
      const { sellerAdd } = SellerStorage();
      userAll(4).then((resp) => {
        const response = resp.data;
        if (response.code == 200) {
          response.data.map((item) => {
            sellerAdd(item);
          });
          this.sellerAll();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.edit-cliet-admin {
  .container {
    padding-top: 25px;
  }
}
</style>
